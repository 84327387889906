//* ↓↓ LIST OF APIs ↓↓
const PROD_API = 'https://front-api.soroban.ua/api';
const TEST_API = 'https://test-web-api.soroban.ua/api';
const TEST_LOCAL_API = 'http://local.soroban.ua:8888/panel';

const HOSTNAME = window.location.hostname;

let URL = PROD_API;

const LOCAL_BACK = 0;
const PROD = 0;

//* ↓↓ API links process ↓↓
if (HOSTNAME.includes('local') || HOSTNAME.includes('192.168')) {
    URL = (PROD && PROD_API) || (LOCAL_BACK && TEST_LOCAL_API) || TEST_API;
} else if (HOSTNAME.includes('test')) {
    URL = (PROD && PROD_API) || TEST_API;
}

export default URL;
