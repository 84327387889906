import { blogConstant as Types } from './../constants';
import { languages } from '../constants/languages';
import { createNewPostDataObject, defaultImage } from '../actions/blog';

const defaultState = {
    loading: false,
    redirect: false,
    data: createNewPostDataObject(languages),
    image: defaultImage,
    rank: '',
    post_name: '',
    language: languages[0],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.INITEDIT:
            return {
                ...state,
                ...action.blogEdit,
                data: action.blogEdit.data || defaultState.data,
            };
        case Types.SET:
            return {
                ...state,
                ...action.blogEdit,
                data: action.blogEdit.data || defaultState.data,
            };
        case Types.SET_SINGLE_ITEM:
            return {
                ...state,
                ...action.blogEdit,
            };
        default:
            return state;
    }
};
