import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import {
    InitEdit,
    SetEdit,
    SaveBlog,
    InitCategory,
    ChangeLanguage,
    EditIndependentItems,
} from '../../../actions/blog';
import CustomInput from './../../elements/CustomInput/CustomInput.jsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import 'react-quill/dist/quill.snow.css';
import { Editor } from '@tinymce/tinymce-react';
import FileBase64 from 'react-file-base64';
import Button from './../../elements/CustomButtons/Button.jsx';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { languages } from '../../../constants/languages';
import {
    CircularProgress,
    Collapse,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import SimpleSnackbar from './snackbar';

const langs = languages.map((el, index) => ({ label: el, value: index }));

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            test: null,
            tabsValue: 0,
            snackBarOpen: false,
            loading: true,
        };
        this.handleModelChange = this.handleModelChange.bind(this);
    }

    componentDidMount() {
        this.processRequests();
    }

    processRequests = async () => {
        await this.props.InitCategory();

        if (this.props.match.params.id === 'new') {
            console.log('NEW');
            await this.props.InitEdit();
        } else {
            console.log('this one');
            await this.props.InitEdit(this.props.match.params.id);
        }
        this.setState({
            loading: false,
        });
    };

    handleChange = e => {
        this.props.SetEdit({ [e.target.id]: e.target.value });
    };

    handleChangeIndependentItems = e => {
        this.props.EditIndependentItems(e.target.id, e.target.value);
    };

    handleChangeRank = e => {
        this.props.EditIndependentItems('rank', e.target.value);
    };
    handleModelChange = model => {
        this.props.SetEdit({ content_ru: model });
    };
    handleSavePost = async e => {
        e.preventDefault();
        this.setState({ loading: true });

        const res = await this.props.SaveBlog();
        if (!res) {
            this.setState({ loading: false });
            return;
        }
        this.handleSnackBar(true);
        this.setState({ loading: false });
        this.props.history.push('/blog/' + this.props.post_name);
        this.processRequests();
    };

    handleSnackBar = state => {
        this.setState({ snackBarOpen: state });
    };

    onDrop = pictureFiles => {
        this.props.EditIndependentItems('image', pictureFiles.base64);
    };
    handleEditorChange = content => {
        this.props.SetEdit({ content });
    };
    test = e => {
        const files = Array.from(e.target.files);
        const formData = new FormData();

        files.forEach((file, i) => {
            formData.append(i, file);
        });
    };

    handleChangeTabs = (event, newValue) => {
        this.props.ChangeLanguage(
            langs.find(el => el.value === newValue).label
        );
    };
    render() {
        const error = this.props.error
            ? this.props.blogEdit.error.result[this.props.language] || false
            : false;
        const blogData = this.props.blogEdit;
        const blog = blogData.data[this.props.language];
        const isRedirect = this.props.blogEdit.redirect;
        const saveButtonText =
            this.props.match.params.id === 'new' ? 'Опубликовать' : 'Сохранить';
        return isRedirect ? (
            <Redirect to="/blog" />
        ) : (
            <>
                <Link to={'/blog'}>
                    <Button size={'sm'} style={{ marginBottom: '15px' }}>
                        <ArrowBackIos fontSize={'small'} />
                        Назад к статьям
                    </Button>
                </Link>
                <div
                    style={{
                        opacity: this.state.loading ? '0.7' : '1',
                        pointerEvents: this.state.loading ? 'none' : 'auto',
                    }}
                >
                    <SimpleSnackbar
                        open={this.state.snackBarOpen}
                        handleSnackBar={this.handleSnackBar}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Tabs
                            value={
                                langs.find(
                                    el => el.label === this.props.language
                                ).value
                            }
                            onChange={this.handleChangeTabs}
                            aria-label="basic tabs example"
                        >
                            {langs.map((el, index) => (
                                <Tab
                                    key={index}
                                    label={el.label}
                                    id={el.label}
                                    aria-controls={`tabpanel-${el.label}`}
                                />
                            ))}
                        </Tabs>

                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    onClick={this.handleSavePost}
                                    color="success"
                                    size="lg"
                                >
                                    {saveButtonText}
                                </Button>
                                {this.state.loading ? (
                                    <div style={{ marginLeft: '10px' }}>
                                        <CircularProgress size={20} />
                                    </div>
                                ) : null}
                            </div>

                            <Collapse in={Boolean(this.props.error)}>
                                <Typography color={'error'}>
                                    Не все поля заполнены!
                                    <br />
                                    Языки:{' '}
                                    {this.props.error &&
                                        this.props.error.result &&
                                        Object.keys(this.props.error.result)
                                            .map(el => el)
                                            .join(', ')}
                                </Typography>
                            </Collapse>
                        </div>
                    </div>
                    {/*<a href={'/blog/' + this.props.match.params.id + '/ru'}>RU</a>*/}
                    {/*<span> --- </span>*/}
                    {/*<a href={'/blog/' + this.props.match.params.id + '/ua'}>UA</a>*/}
                    <CustomInput
                        error={Boolean(error['title'])}
                        labelText="Название статьи"
                        id="title"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            onChange: this.handleChange,
                            value: blog.title || '',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className="inputIconsColor">
                                        title
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomInput
                        error={Boolean(error['seo_title'])}
                        labelText="Название статьи (SEO)"
                        id="seo_title"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            onChange: this.handleChange,
                            value: blog.seo_title || '',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className="inputIconsColor">
                                        title
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomInput
                        error={Boolean(error['digest'])}
                        labelText="Краткое описание"
                        id="digest"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            onChange: this.handleChange,
                            value: blog.digest || '',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className="inputIconsColor">
                                        title
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomInput
                        error={Boolean(error['seo_descriptions'])}
                        labelText="Краткое описание (SEO)"
                        id="seo_descriptions"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            onChange: this.handleChange,
                            value: blog.seo_descriptions || '',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className="inputIconsColor">
                                        title
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomInput
                        error={Boolean(error['post_name'])}
                        labelText="Ссылка"
                        id="post_name"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            onChange: this.handleChangeIndependentItems,
                            value: this.props.post_name || '',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon className="inputIconsColor">
                                        link
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">
                            Категория
                        </InputLabel>
                        <Select
                            error={Boolean(error['rank'])}
                            labelId="demo-simple-select-helper-label"
                            id="rank"
                            value={blogData.rank || ''}
                            onChange={this.handleChangeRank}
                        >
                            <MenuItem value={''}>Выберите категорию</MenuItem>
                            {(this.props.blog.categories || []).map(
                                (el, index) => (
                                    <MenuItem value={el.rank} key={index}>
                                        {el[this.props.language.toLowerCase()]}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                    <div className="uploadImage">
                        <div
                            className="image"
                            style={{
                                backgroundImage: `url(${blogData.image})`,
                            }}
                        />
                        <label>
                            <p>Загрузить изображение</p>
                            <FileBase64 multiple={false} onDone={this.onDrop} />
                        </label>
                    </div>
                    {Boolean(error['image']) ? (
                        <Typography color={'error'}>
                            Загрузите изображение
                        </Typography>
                    ) : null}
                    <Editor
                        key={this.props.language}
                        initialValue={
                            this.props.blogEdit &&
                            this.props.blogEdit.data &&
                            this.props.blogEdit.data[this.props.language] &&
                            this.props.blogEdit.data[this.props.language]
                                .content
                        }
                        onEditorChange={this.handleEditorChange}
                        apiKey="hh8mxug0z7gvfjlsj4gskv87kf5dryzuiqet2et1y73lhbg0"
                        init={{
                            paste_data_images: true,
                            height: 600,
                            images_upload_url: 'postAcceptor.php',
                            automatic_uploads: false,
                            plugins: 'link image code',
                            toolbar:
                                'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
                        }}
                    />
                    {Boolean(error['content']) ? (
                        <Typography color={'error'}>
                            Нету текста статьи
                        </Typography>
                    ) : null}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            onClick={this.handleSavePost}
                            color="success"
                            size="lg"
                        >
                            {saveButtonText}
                        </Button>
                        {this.state.loading ? (
                            <div style={{ marginLeft: '10px' }}>
                                <CircularProgress size={20} />
                            </div>
                        ) : null}
                    </div>
                    <Collapse in={Boolean(this.props.error)}>
                        <Typography color={'error'}>
                            Не все поля заполнены! <br />
                            Языки:{' '}
                            {this.props.error &&
                                this.props.error.result &&
                                Object.keys(this.props.error.result)
                                    .map(el => el)
                                    .join(', ')}
                        </Typography>
                    </Collapse>
                </div>
            </>
        );
    }
}

const widthConnect = connect(
    state => ({
        blog: state.blog,
        blogEdit: state.blogEdit,
        language: state.blogEdit && state.blogEdit.language,
        post_name: state.blogEdit && state.blogEdit.post_name,
        error: state.blogEdit && state.blogEdit.error,
    }),
    dispatch =>
        bindActionCreators(
            {
                InitEdit,
                SetEdit,
                SaveBlog,
                InitCategory,
                ChangeLanguage,
                EditIndependentItems,
            },
            dispatch
        )
);

export default widthConnect(Blog);
