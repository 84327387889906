import { blogConstant as Types } from './../constants';
import axios from 'axios';
import API from './../constants/API';
import { languages } from '../constants/languages';
import { validateData } from './validation';
const defaultImage =
    'https://uaprom-static.c.prom.st/image/new_design/images/no_image-hce614324446b22b42a09b69093e309fce.png';
function Init() {
    return async (dispatch, getState) => {
        const { blog } = getState();

        const pagination = blog.pagination.activePage || 1;

        dispatch({
            type: Types.INIT,
            blog: {
                searchActive: false,
                loading: true,
                pagination: {
                    activePage: pagination,
                    active: true,
                },
            },
        });
        const limit = 12 * pagination;
        const skip = 12 * (pagination - 1);
        // :(
        const domain = window.location.host.split('.')[
            // eslint-disable-next-line no-unexpected-multiline
            window.location.host.split('.').length - 1
        ].split(':')[0];

        await axios
            .get(
                `${API}/blog/${domain}?limit=${limit}&skip=${skip}&token=!Aa12345&notcache=${+new Date()}`
            )
            .then(response => {
                if (response.status === 200 && response.data) {
                    const dataResponse = response.data;
                    dataResponse.blogs = dataResponse.blogs.map(blog => {
                        return {
                            ...blog,
                            title: blog.title,
                            digest: blog.digest,
                            content: blog.content,
                        };
                    });
                    dispatch({
                        type: Types.INIT,
                        blog: {
                            data: dataResponse.blogs,
                            pagination: {
                                ...blog.pagination,
                                active: true,
                                total: dataResponse.total,
                            },
                            searchActive: false,
                            loading: true,
                        },
                    });
                    dispatch({
                        type: Types.INITEDIT,
                        blogEdit: { loading: false, redirect: false },
                    });
                    return true;
                }
                return false;
            });
    };
}

function SearchForPost(searchState) {
    return async (dispatch, getState) => {
        const domain = window.location.host.split('.')[
            // eslint-disable-next-line no-unexpected-multiline
            window.location.host.split('.').length - 1
        ].split(':')[0];
        await axios
            .get(
                `${API}/blog/${domain}?limit=${99999}&skip=${0}&token=!Aa12345&notcache=${+new Date()}`
            )
            .then(res => {
                if (res.status === 200 && res.data) {
                    const dataResponse = res.data.blogs;
                    const result =
                        dataResponse.length &&
                        dataResponse.find(el => {
                            const value = searchState.replaceAll(' ', '');
                            const titleEN = el.title['en']
                                .replaceAll(' ', '')
                                .includes(value);
                            const titleRU = el.title['ru']
                                .replaceAll(' ', '')
                                .includes(value);
                            const titleCZ = el.title['cz']
                                .replaceAll(' ', '')
                                .includes(value);
                            if (titleCZ || titleEN || titleRU) {
                                return el;
                            }
                            return null;
                        });
                    if (result) {
                        dispatch({
                            type: Types.INIT,
                            blog: {
                                data: [result],
                                pagination: {
                                    active: false,
                                },
                                searchActive: true,
                                loading: true,
                            },
                        });
                        dispatch({
                            type: Types.INITEDIT,
                            blogEdit: { loading: false, redirect: false },
                        });
                    }
                    return true;
                }
                return false;
            });
    };
}

function InitCategory() {
    return (dispatch, getState) => {
        const { blog } = getState();
        // :(
        axios.get(`${API}/categories`).then(response => {
            if (response.status === 200 && response.data) {
                const dataResponse = response.data;

                dispatch({
                    type: Types.INIT,
                    blog: {
                        ...blog,
                        categories: dataResponse,
                        searchActive: false,
                    },
                });
            }
        });
    };
}

async function singleRequest(countries, id) {
    const result = {};
    const domain = window.location.host
        .split('.') // eslint-disable-next-line no-unexpected-multiline
        [window.location.host.split('.').length - 1].split(':')[0];
    const promises = countries.map(async country => {
        const response = await axios.get(
            `${API}/blog/` +
                domain +
                '/single/' +
                id +
                `?token=!Aa12345&notcache=${+new Date()}&lang=${country.toUpperCase()}`
        );
        if (response.status === 200 && response.data) {
            const dataResponse = response.data;
            result[country] = {
                language: country.toUpperCase(),
                ...dataResponse,
            };
        }
    });

    await Promise.all(promises);
    return result;
}

function ChangeLanguage(language) {
    return async dispatch => {
        dispatch({
            type: Types.SET_SINGLE_ITEM,
            blogEdit: {
                language,
            },
        });
    };
}

function createNewPostDataObject(countries) {
    const result = {};

    countries.forEach(country => {
        result[country] = {
            language: country,
            title: '',
            content: '',
            digest: '',
            seo_url: '',
            seo_title: '',
            seo_descriptions: '',
        };
    });

    return result;
}

function InitEdit(id) {
    return async dispatch => {
        dispatch({
            type: Types.SET_SINGLE_ITEM,
            blogEdit: {
                loading: true,
            },
        });
        if (id) {
            const data = await singleRequest(languages, id);

            dispatch({
                type: Types.INITEDIT,
                blogEdit: {
                    data,
                    loading: false,
                    redirect: false,
                    language: languages[0],
                    post_name: data[languages[0]].post_name,
                    image: data[languages[0]].image,
                    rank: data[languages[0]].rank,
                },
            });
            // if (blog.loading) {
            //     const blogData = blog.data.filter(
            //         blog =>
            //             blog.post_name === id &&
            //             blog.language === 'ua'.toUpperCase()
            //     )[0];
            //     console.log(blogData);
            //     dispatch({
            //         type: Types.INITEDIT,
            //         blogEdit: {
            //             data: blogData,
            //             loading: true,
            //             redirect: false,
            //         },
            //     });
            // } else {
            //
            // }
        } else {
            dispatch({
                type: Types.INITEDIT,
                blogEdit: {
                    loading: false,
                    redirect: false,
                    data: createNewPostDataObject(languages),
                    language: languages[0],
                    post_name: '',
                    image: defaultImage,
                    rank: '',
                },
            });
        }
    };
}

function SaveBlog() {
    return async (dispatch, getState) => {
        let result = {};
        dispatch(EditIndependentItems('error', false));

        const { blogEdit, user } = getState();
        const { post_name, image, rank } = blogEdit;
        const newValues = JSON.parse(JSON.stringify(blogEdit.data));
        Object.keys(newValues).forEach(country => {
            const item = newValues[country];
            newValues[country] = {
                ...item,
                post_name,
                image,
                rank,
            };
        });
        const validation = validateData(newValues);
        if (!validation.error) {
            const promises = Object.keys(blogEdit.data).map(async country => {
                result[country] = await axios({
                    method: 'post',
                    url: `${API}/blog/`,
                    data: newValues[country],
                    headers: { auth: user.info },
                });
            });

            await Promise.all(promises);
            return true;
        }
        if (validation.error) {
            dispatch(EditIndependentItems('error', validation));
            setTimeout(() => {
                dispatch(EditIndependentItems('error', false));
            }, 7000);
            return false;
        }
    };
}

function setPage(page) {
    return (dispatch, getState) => {
        const { blog } = getState();
        dispatch({
            type: Types.SET_PAGINATION,
            blog: {
                ...blog,
                pagination: {
                    ...blog.pagination,
                    activePage: page,
                    active: true,
                },
                searchActive: false,
                loading: true,
            },
        });
        Init()(dispatch, getState);
    };
}

function deleteBlog(id) {
    return async (dispatch, getState) => {
        const { user } = getState();
        const domain = window.location.host
            .split('.') // eslint-disable-next-line no-unexpected-multiline
            [window.location.host.split('.').length - 1].split(':')[0];
        const res = await axios({
            method: 'delete',
            url: `${API}/blog/${domain}/${id}?token=!Aa12345`,
            headers: { auth: user.info },
            config: {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        });
        return (res && res.data && res.data.success) || false;
    };
}

function SetEdit(obj) {
    return (dispatch, getState) => {
        const { blogEdit } = getState();
        const { language } = blogEdit;
        dispatch({
            type: Types.SET,
            blogEdit: {
                data: {
                    ...blogEdit.data,
                    [language]: { ...blogEdit.data[language], ...obj },
                },
            },
        });
    };
}

function EditIndependentItems(name, value) {
    return async dispatch => {
        dispatch({
            type: Types.SET_SINGLE_ITEM,
            blogEdit: {
                [name]: value,
            },
        });
    };
}

export {
    Init,
    InitEdit,
    SetEdit,
    SaveBlog,
    deleteBlog,
    setPage,
    InitCategory,
    ChangeLanguage,
    EditIndependentItems,
    createNewPostDataObject,
    defaultImage,
    SearchForPost,
};
