import { userConstant as Types } from './../constants';
import axios from 'axios';
import API from '../constants/API';
function Init() {
    return (dispatch, getState) => {
        const { user } = getState();
        const storageData = localStorage.getItem('user_info');
        if (storageData) {
            dispatch({
                type: Types.INIT,
                user: { ...user.info, info: storageData },
            });
        } else
            dispatch({
                type: Types.INIT,
                user: { ...user.info, user: { info: null } },
            });
    };
}

function login(data) {
    return (dispatch, getState) => {
        const { user } = getState();
        axios({
            method: 'post',
            url: `${API}/login?token=!Aa12345`,
            data: data,
            config: {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        }).then(function(response) {
            if (response.status === 200 && response.data) {
                const dataResponse = response.data;
                localStorage.setItem(
                    'user_info',
                    JSON.stringify({
                        user: {
                            info: dataResponse.token,
                            loggedin: dataResponse.success,
                        },
                    })
                );
                dispatch({
                    type: Types.LOGIN,
                    user: {
                        ...user.info,
                        info: dataResponse.token,
                        loggedin: dataResponse.success,
                    },
                });
            }
        });
    };
}

function logout() {
    return (dispatch, getState) => {
        const { user } = getState();
        const storageData = localStorage.getItem('user_info');
        if (storageData) {
            const localStorageDataUser = JSON.parse(storageData)['user'];
            localStorageDataUser.info = null;
            localStorageDataUser.loggedin = false;
            localStorage.setItem(
                'user_info',
                JSON.stringify({ user: localStorageDataUser })
            );
            dispatch({
                type: Types.LOGOUT,
                user: {
                    ...user.info,
                    info: localStorageDataUser.info,
                    loggedin: localStorageDataUser.loggedin,
                },
            });
        }
    };
}

export { Init, login, logout };
