import React from 'react';

import { Init, setPage, deleteBlog, SearchForPost } from '../../actions/blog';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CardBody from './../elements/Card/CardBody.jsx';
import Button from './../elements/CustomButtons/Button.jsx';
import Pagination from 'react-js-pagination';
import { CircularProgress } from '@material-ui/core';
import Card from '../elements/Card/Card';
import { Delete } from '@material-ui/icons';
import CustomInput from '../elements/CustomInput/CustomInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import SimpleSnackbarBlog from './snackbar';

class Blogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            language: 'cz',
            search: '',
            searchLoading: false,
            snackBarOpen: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    setLoading = loading => {
        this.setState({
            loading,
        });
    };

    handleSnackBar = state => {
        this.setState({ snackBarOpen: state });
    };

    getData = async () => {
        this.setLoading(true);
        await this.props.Init();
        this.setLoading(false);
    };

    deletePostClick = async id => {
        const userConfirmation = window.prompt(
            'Вы уверены, что хотите удалить этот пост? Введите "delete" для подтверждения.'
        );

        if (userConfirmation === 'delete') {
            await this.props.deleteBlog(id);
            this.handleSnackBar(true);
            this.getData();
        }
    };

    handleNavigateToPost = blog => {
        this.props.history.push('/blog/' + blog.post_name);
    };

    handlePageChange = pageNumber => {
        this.props.setPage(pageNumber);
    };

    handleChange = e => {
        this.setState({
            search: e.target.value,
        });
    };

    handleSearch = async () => {
        this.setState({ searchLoading: true });
        await this.props.SearchForPost(this.state.search);
        this.setState({ searchLoading: false });
    };

    handleClearSearch = () => {
        this.setState({ searchLoading: false, search: '' });
        this.getData();
    };

    render() {
        return (
            <div>
                <div>
                    <CustomInput
                        labelText="Пошук статті"
                        id="search"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            onChange: this.handleChange,
                            value: this.state.search,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {(this.state.searchLoading && (
                                        <CircularProgress size={20} />
                                    )) || (
                                        <Icon className="inputIconsColor">
                                            title
                                        </Icon>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        onClick={this.handleSearch}
                        disabled={this.state.searchLoading}
                    >
                        Пошук
                    </Button>
                    {this.props.searchActive && (
                        <Button
                            onClick={this.handleClearSearch}
                            color={'danger'}
                        >
                            Скинути пошук
                        </Button>
                    )}
                </div>
                <div
                    className="container-blog"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginBottom: '100px',
                    }}
                >
                    {this.state.loading ? (
                        <CircularProgress />
                    ) : (
                        this.props.blog.data.map((blog, index) => (
                            <Card key={index} style={{ maxWidth: '300px' }}>
                                <img
                                    style={{
                                        height: '200px',
                                        width: '100%',
                                        display: 'block',
                                        borderRadius: '6px 6px 0px 0px',
                                        objectFit: 'cover',
                                        objectPosition: 'top',
                                    }}
                                    className="imgCardTop"
                                    src={blog.image}
                                    alt="Card-img-cap"
                                    onClick={() =>
                                        this.handleNavigateToPost(blog)
                                    }
                                />
                                <CardBody
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <h4 className="cardTitle">
                                            {blog.title[this.state.language]}
                                        </h4>
                                        <p>
                                            {blog.digest[this.state.language]}
                                        </p>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Link
                                            to={'/blog/' + blog.post_name}
                                            style={{
                                                color: '#fff',
                                                width: '100%',
                                                marginRight: '5px',
                                            }}
                                        >
                                            <Button color="primary" fullWidth>
                                                Просмотр
                                            </Button>
                                        </Link>
                                        <Button
                                            color={'danger'}
                                            onClick={this.deletePostClick.bind(
                                                false,
                                                blog.id
                                            )}
                                            justIcon
                                        >
                                            <Delete />
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        ))
                    )}{' '}
                </div>
                {this.props.paginationState && (
                    <Pagination
                        activePage={this.props.blog.pagination.activePage}
                        itemsCountPerPage={12}
                        totalItemsCount={this.props.blog.pagination.total}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                    />
                )}
                <SimpleSnackbarBlog
                    open={this.state.snackBarOpen}
                    handleSnackBar={this.handleSnackBar}
                />
            </div>
        );
    }
}
// activePage={this.state.activePage}
const widthConnect = connect(
    state => ({
        blog: state.blog,
        paginationState:
            state.blog && state.blog.pagination && state.blog.pagination.active,
        searchActive: state.blog && state.blog.searchActive,
    }),
    dispatch =>
        bindActionCreators(
            { Init, setPage, deleteBlog, SearchForPost },
            dispatch
        )
);

export default widthConnect(Blogs);
