import { defaultImage } from './blog';

const schema = [
    'title',
    'seo_title',
    'digest',
    'seo_descriptions',
    'post_name',
    'rank',
    'image',
    'content',
];

const validateData = postData => {
    let result = {};
    for (const language in postData) {
        const post = postData[language];

        for (const items of schema) {
            const postItem = post[items];
            if (!postItem) {
                result[language] = {
                    ...result[language],
                    [items]: true,
                };
            } else if (items === 'image' && postItem === defaultImage) {
                result[language] = {
                    ...result[language],
                    [items]: true,
                };
            }
        }
    }

    if (!Object.keys(result).length) {
        return {
            error: false,
        };
    }
    return { error: true, result };
};

export { validateData };
