import React from 'react';
import Header from './../../components/Header/Header.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Button from './../../components/elements/CustomButtons/Button.jsx';
import CustomDropdown from './../../components/elements/CustomDropdown/CustomDropdown.jsx';
// import profileImage from "./../../assets/img/faces/avatar.jpg";
import { NavLink, Link } from 'react-router-dom';

import { logout } from '../../actions/user';

import './App.css';

class App extends React.Component {
    handleClickLogin = e => {
        e.preventDefault();
        this.props.logout();
    };
    render() {
        const name = this.props.name;
        const children = this.props.children;
        const isBlog = children[0].props.path === '/blog';
        return (
            <div className="App">
                <Header
                    brand="Admin panel for Soroban"
                    color="dark"
                    className="header-nav"
                    rightLinks={
                        <List className="list">
                            <ListItem className="listItem">
                                <CustomDropdown
                                    right
                                    caret={false}
                                    hoverColor="black"
                                    dropdownHeader="Панель управления"
                                    buttonText={<div className="img" />}
                                    buttonProps={{
                                        className:
                                            'navLink imageDropdownButton',
                                        color: 'transparent',
                                    }}
                                    dropdownList={[
                                        'Мой профиль',
                                        <button onClick={this.handleClickLogin}>
                                            Выйти
                                        </button>,
                                    ]}
                                />
                            </ListItem>
                        </List>
                    }
                />
                <div className="body-content">
                    <div className="sidebar">
                        <ul>
                            <li>
                                <NavLink exact to="/dashboard">
                                    <Button type="button">Главная</Button>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/blog">
                                    <Button type="button">Блог</Button>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <main>
                        <div className="content-title">
                            {name}
                            <div className="filter">
                                {' '}
                                {isBlog ? (
                                    <Link to="/blog/new">Создать</Link>
                                ) : null}{' '}
                            </div>
                        </div>
                        <div className="content-body">{children}</div>
                    </main>
                </div>
            </div>
        );
    }
}

const widthConnect = connect(
    state => ({ isAuthenticated: state.user && state.user.loggedin }),
    dispatch => bindActionCreators({ logout }, dispatch)
);

export default widthConnect(App);
